export default {
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MobiAds"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Агентство мобильного маркетинга"])},
  "nav": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Главная"])},
    "cases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кейсы"])},
    "solutions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Решения"])},
    "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Услуги"])},
    "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контакты"])},
    "creative-lab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Креативная лаборатория"])},
    "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свяжитесь с нами"])},
    "career": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вакансии"])}
  },
  "introHome": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Агентство мобильного маркетинга"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы помогаем бизнесу находить новых клиентов"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10 лет экспертизы на рынке помогают нам погрузиться в проект, разработать и реализовать для вас комплексную стратегию по привлечению новых пользователей."])}
  },
  "introCases": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наши кейсы"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реальные проекты с отличными результатами"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наша миссия — помогать расти вашему бизнесу, используя всю мощь мобильных устройств и современных рекламных технологий."])},
    "clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наши клиенты"])}
  },
  "introSolutions": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Медиабаинг"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Правильные решения для достижения лучших результатов"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы работаем только с качественными источниками трафика, которые приносят максимальную выгоду нашим клиентам."])},
    "sources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наши источники"])}
  },
  "introServices": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наши услуги"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продвижение мобильных приложений"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы закрываем полный спектр ваших потребностей в маркетинге мобильных приложений, начиная с разработки стратегии и заканчивая оптимизацией конверсий с каждого баннера."])}
  },
  "sectionList": {
    "section1": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MobiAds In-App Network"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MobiAds In-App Network работает с крупнейшими рекламными платформами и издателями игр. Мы сгенерировали более 1 миллиона лидов и обеспечили более 500 миллионов регистраций за год."])}
    },
    "section2": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Медиабаинг"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["150 медиабайеров и собственная технология по оптимизации трафика гарантируют качественных юзеров и возврат инвестиций в короткие сроки."])}
    },
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Узнать больше"])}
  },
  "whywe": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Почему вам стоит выбрать нас?"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MobiAds — международный проект, существует на рынке уже 10 лет. Лидер в разработке и продвижении игровых и неигровых мобильных приложений в вертикалях e-commerce, потоковое видео, доставка еды, спорт, услуги, финансы."])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Связаться с нами"])}
  },
  "contactsBlock": {
    "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контакты"])},
    "subtitle1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всегда на связи!"])},
    "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Остались вопросы?"])},
    "subtitle2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свяжитесь с нами!"])},
    "title3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Просто оставьте заявку — и мы сделаем такие же вовлекающие креативы для вас."])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если вы хотите получить больше информации о нас, заполните форму для сообщения или свяжитесь с нами любым другим удобным для вас способом."])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["телефон"])},
    "vk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["вконтакте"])}
  },
  "feedbackForm": {
    "errorField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заполните это поле"])},
    "errorMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите правильный email"])},
    "label": {
      "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш контакт (email, skype, telegram и т.п.)"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваше сообщение"])},
      "policyCheckbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я согласен на обработку моих персональных данных в маркетинговых целях в соответствии с Политикой конфиденциальности"])},
      "newsLettersCheckbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Согласен получать информационные рассылки от MobiAds"])}
    },
    "isMessageSended": {
      "success": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Спасибо."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы получили вашу заявку и в ближайшее время мы свяжемся с вами!"])}
      },
      "fail": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По техническим причинам ваше сообщение не может быть доставлено."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Попробуйте еще раз чуть позже."])}
      }
    },
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить сообщение"])}
  },
  "footer": {
    "politics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Политика конфиденциальности"])}
  },
  "cases": {
    "okko": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OKKO"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Крупнейший российский онлайн-кинотеатр. В коллекции сервиса более 100 тысяч фильмов, мультфильмов, сериалов, спортивных программ и трансляций."])},
      "info": {
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["установок"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["регистраций"])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CR/год"])}
      }
    },
    "delimobil": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delimobil"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сервис краткосрочной аренды автомобилей, запущен в 2015 году. Каршеринг работает в 10 городах России, численность автопарка составляет 20 тысяч автомобилей."])},
      "info": {
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["регистраций/год"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["поездок/год"])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["охват"])},
        "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все города присутствия Делимобиля на территории РФ"])}
      }
    },
    "winline": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Winline"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лицензированный букмекер, один из лидеров рынка. Компания основана в 2009 году, широко известна на рынке букмекерских услуг в России и странах СНГ."])},
      "info": {
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["регистраций/год"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["первых депозитов/год"])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["вторых депозитов/год"])},
        "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["присутствие в YouTubе, категория спорт"])}
      }
    },
    "stoloto": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Столото"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бренд крупнейшего распространителя государственных лотерей. Насчитывает более 25 тысяч точек распространения в России. Организаторы: Министерство финансов РФ и Министерство спорта РФ."])},
      "info": {
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["регистраций/год"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фокус/Кампания с одним каналом коммуникации в TikTok"])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Награда Effie 2021"])}
      }
    }
  },
  "ourstat": {
    "name1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["источников трафика"])},
    "name2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["установок в год"])},
    "name3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["стран мира"])}
  },
  "process": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Процесс"])},
    "step1": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаг 1"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователь видит рекламу приложения в одном из наших источников"])}
    },
    "step2": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаг 2"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нажимает на объявление и попадает на страницу маркетплейса"])}
    },
    "step3": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаг 3"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скачивает <br>приложение"])}
    },
    "step4": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаг 4"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Открывает приложение и регистрируется"])}
    }
  },
  "benefits": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наши преимущества"])},
    "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматизация"])},
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Технологии по оптимизации трафика позволяют получить максимальную прибыль от инвестиций."])},
    "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Креатив"])},
    "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Креативный отдел знает, с помощью каких подходов привлечь и “зацепить” вашу целевую аудиторию."])},
    "title3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Антифрод"])},
    "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Собственная антифрод система защищает наших клиентов от мошенничества и некачественного трафика."])},
    "title4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Качество и объемы"])},
    "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["За счет накопленной экспертизы генерируем большие объемы качественного трафика в сжатые сроки."])},
    "title5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клиентский сервис"])},
    "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прозрачный подход к работе, еженедельные отчеты и выделенный менеджер позволяют предоставлять сервис на высшем уровне."])}
  },
  "performance": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Performance-маркетинг приложений"])},
    "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В концепции нашей работы лежит Performance-маркетинг — продвижение приложений брендов без абстрактных цифр, с точными результатами и достижением KPI, а также возврат инвестиций (ROI) в короткие сроки. Данный принцип идеально подходит для тех, кто не хочет тратить деньги впустую."])},
    "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чаще всего Performance-маркетинг используется при бюджете на рекламу от 200 тысяч рублей. При более скромном бюджете с его помощью можно орудовать базовыми инструментами. Например, принимать решение о финансировании того или иного рекламного канала, смотреть статистику эффективности рекламных инструментов."])}
  },
  "performanceHelp": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["С помощью Performance-маркетинга можно:"])},
    "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Измерить результаты эксплуатируемых каналов по рекламе"])},
    "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оценить бизнес-показатели: CPA и ROI"])},
    "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В реальном времени повлиять на результаты продвижения"])},
    "p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Синхронизировать работу всех каналов продвижения (SEO, SMM, email-рассылки, реклама в СМИ)"])}
  },
  "performanceAso": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASO (App Store оптимизация)"])},
    "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASO или App Store Optimization — это комплексный процесс оптимизации страницы мобильного приложения в магазинах App Store и Google Play с целью максимизации его видимости и конверсии посетителей в скачивания, и  включает в себя полную текстовую оптимизацию название, описания приложения, а также графических материалов."])},
    "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В контексте работы с продвижением приложения, ASO — это фундамент. <br> Чем детальнее и тщательнее он проработан, тем лучший результат по рекламным кампаниям можно получить в дальнейшем. <br> ASO решает основные задачи бизнеса: <br> — увеличение видимости приложения; <br> — увеличение конверсии; <br> — улучшение рейтинга."])}
  },
  "performanceAsoBenefits": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Что вы получите после проведения ASO?"])},
    "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Индексацию по ключевым словам: ваше приложение легко найти в поиске магазинов приложений по разным ключевым словам."])},
    "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Высокие показатели органических установок и их планомерный рост: все инструменты ASO напрямую влияют на органический трафик."])},
    "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Меньшую подверженность изменениям условий сторов: у каждого магазина приложений свои особенности, влияющие на стабильность ASO показателей."])},
    "p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Визуальные элементы показывают хорошую конверсию: пользователь легко идентифицирует ваше приложение в конкурентном поле, быстрее захватывая основную информацию."])}
  },
  "creativeLab": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Креативная лаборатория Mobi<span>Ads</span>"])},
    "info1": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дизайн"])},
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Креативы определяют 50% успеха рекламной кампании"])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проверка гипотез позволяет увеличивать вовлеченность"])},
      "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Преимущество большого опыта на рынке выделяют нас среди конкурентов"])}
    },
    "info2": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продакшн"])},
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ресурсы для поддержки ваших идей (большой штат дизайнеров и т.д.)"])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Достаточный объем мощностей, поэтому кампании никогда не останавливаются"])},
      "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статические изображения, баннеры, 2D-видео, 3D-видео и т.д."])}
    }
  },
  "metaHome": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MobiAds - Профессиональное продвижение мобильных приложений"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы предлагаем комплексные решения для привлечения новых пользователей и увеличения видимости вашего приложения. Наша команда экспертов обеспечит вам качественные результаты и быстрый возврат инвестиций."])}
  },
  "metaCases": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MobiAds - Успешные кейсы в мобильном маркетинге"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наши кейсы демонстрируют, как мы используем инновационные подходы и технологии для достижения впечатляющих результатов в мобильном маркетинге."])}
  },
  "metaSolutions": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реклама мобильных приложений с MobiAds - достижение лучших результатов"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MobiAds предлагает эффективные решения для рекламы мобильных приложений. Наша команда экспертов использует автоматизацию, креативные подходы и антифрод системы для генерации качественного трафика и обеспечения максимальной прибыли от ваших инвестиций."])}
  },
  "metaServices": {
    "title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["ASO и Перформанс-маркетинг для мобильных приложений"]), _normalize(["MobiAds"])])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MobiAds предлагает комплексные услуги по продвижению мобильных приложений (ASO) и Перформанс-маркетингу. Мы помогаем увеличить видимость вашего приложения в Google Play и App Store, максимизируя конверсию и достигая ваших KPI"])}
  },
  "metaCreativeLab": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MobiAds - Профессиональное создание креативов для рекламных кампаний"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Креативная лаборатория MobiAds предлагает полный спектр услуг по созданию креативов для рекламных кампаний. Мы создаем статические изображения, баннеры, 2D- и 3D-видео, которые увеличивают вовлеченность и делают вашу рекламу незабываемой"])}
  },
  "metaContacts": {
    "title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Свяжитесь с нами"]), _normalize(["MobiAds"])])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В MobiAds мы всегда на связи и готовы ответить на все ваши вопросы. Вы можете связаться с нами по телефону +7 499 110-07-39, отправить письмо на welcome-mobiads.io или написать нам в ВКонтакте."])}
  },
  "careerPage": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вакансии"])},
    "text": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы верим, что доверие к нашим партнерам и командам начинается с нас самих."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наши сотрудники уверены в своих силах, доверяют своему опыту и интуиции, и мы продолжаем помогать им развиваться как профессионально, так и лично."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы глубоко ценим простую истину - успех команды и агентства в целом зависит от гармонии и профессионализма каждого сотрудника."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чтобы достичь нашей глобальной цели, мы сосредотачиваемся на разработке эффективных стратегий, разбивая их на конкретные задачи. Мы уделяем внимание деталям, потому что верим, что хорошо разработанный план позволяет каждому члену команды стать неотъемлемой частью нашего общего успеха."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сегодня наша цель - стать лучшим агентством мобильного маркетинга в мире."])}
    ],
    "badges": {
      "badge1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Возможности профессионального роста"])},
      "badge2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гибкий график работы"])},
      "badge3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тимбилдинг"])},
      "badge4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Занятия с психологом"])},
      "badge5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скидки на изучение языков"])},
      "badge6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ДМС"])},
      "badge7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Корпоративные мероприятия"])}
    },
    "vacancyTitles": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чем предстоит заниматься:"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Что мы ждем:"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы предлагаем:"])}
    ],
    "buttonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить отклик"])},
    "form": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свяжитесь с нами!"])},
      "label": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Почта"])},
        "policyCheckbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ознакомлен с <a href=\"https://mobiads.io/docs/Personal_Data_Processing_and_Privacy_Policy_MOBIADS_en.pdf\">политикой конфиденциальности</a>"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить"])}
      }
    }
  },
  "vacancies": [
    {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senior Sales Manager (Digital)"])},
      "info": {
        "responsibilities": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Искать новых рекламодателей, согласовывать условия для долгосрочных отношений."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Развивать бизнес: представлять интересы агентства в новых сделках и защищать предложения перед текущими клиентами."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Собирать обратную связь, много общаться, переписываться, быть проактивным."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Участвовать в профильных конференциях."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поддерживать продуктивные и долгосрочные взаимоотношения с новыми и текущими клиентами."])}
        ],
        "requirements": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опыт работы в b2b продажах, с большими бюджетами и опыт взаимодействия с крупными клиентами."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Понимание устройства рынка мобильной рекламы и знание основных игроков."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опыт работы на аналогичной должности от 2 лет и более, будет преимуществом опыт в агентской или диджитал-сфере."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клиентоориентированное мышление и предпринимательский дух."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отличные коммуникативные навыки, легкость в общении и взаимодействии с окружающими."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Практический склад ума и умение работать в команде."])}
        ],
        "offer": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гибкий график и гибридный формат работы: рабочая неделя 5/2 с возможностью гибкого начала рабочего дня, дополнительные выходные."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Конкурентоспособную и стабильную зарплату и регулярные бонусы."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комфортные офисы в Москве с полноценным питанием, регулярные ивенты и другие бонусы, включая комнату отдыха с PS5, настольным теннисом, массажными креслами."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Драйвовую атмосферу: полное отсутствие бюрократии, формализма, легаси и открытые коммуникации."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Работу в профессиональной международной команде: возможность работать с коллегами, профессионалами своего дела мирового уровня, регулярное участие в международных конференциях и повышение квалификации."])}
        ]
      }
    },
    {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senior Account Manager"])},
      "info": {
        "responsibilities": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обязателен опыт работы от года в Digital/Mobile/CPA на стороне агентства или CPA-сети."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Понимание специфики рынка мобильного маркетинга."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опыт работы с различными вертикалями."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Глубокая экспертиза и опыт работы с моделями: CPI, CPA, LTV, RevShare."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Навыки работы с инструментами: Excel, Google Sheet, Facebook Business Manager, Appslyer, Adjust, Affise."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аналитический склад ума, внимательность, честность, сообразительность и развитые коммуникативные навыки."])}
        ],
        "requirements": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Развитием долгосрочных отношений и сопровождением клиентов по закупке мобильного трафика."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Up-sales."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Менеджментом проектов и координацией участников."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разработкой и имплементацией медиапланов, ведением регулярной отчетности."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контролировать и управлять дебиторской задолженностью."])}
        ],
        "offer": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гибкий график и гибридный формат работы: рабочая неделя 5/2 с возможностью гибкого начала рабочего дня, дополнительные выходные."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Конкурентоспособную и стабильную зарплату и регулярные бонусы."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комфортные офисы в Москве с полноценным питанием, регулярные ивенты и другие бонусы, включая комнату отдыха с PS5, настольным теннисом, массажными креслами."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Драйвовую атмосферу: полное отсутствие бюрократии, формализма, легаси и открытые коммуникации."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Работу в профессиональной международной команде: возможность работать с коллегами, профессионалами своего дела мирового уровня, регулярное участие в международных конференциях и повышение квалификации."])}
        ]
      }
    },
    {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Junior Account Manager"])},
      "info": {
        "responsibilities": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отслеживание вместе с наставником состояния рекламных кампаний."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подготовка регулярных внутренних отчетов, аналитики для клиентов."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подготовка счетов и закрывающих документов для рекламодателей."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разработка необходимой документации для банков."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Четкое соблюдение рабочих инструкций, гайдов и правил."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Согласование всех рабочих процессов с наставником."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Своевременное выполнение поставленных задач."])}
        ],
        "requirements": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хороший уровень владения MS Excel."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аналитические способности."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Понимание основных рекламных метрик: CPC, CPA, LTV, REVSHARE и.т.д."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Владение английским языком - Intermediate (желательно)."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Умение быстро реагировать на сложные ситуации, находчивость, креативность, ответственность, стрессоустойчивость."])}
        ],
        "offer": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гибкий график и гибридный формат работы: рабочая неделя 5/2 с возможностью гибкого начала рабочего дня, дополнительные выходные."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Конкурентоспособную и стабильную зарплату и регулярные бонусы."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комфортные офисы в Москве с полноценным питанием, регулярные ивенты и другие бонусы, включая комнату отдыха с PS5, настольным теннисом, массажными креслами."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Драйвовую атмосферу: полное отсутствие бюрократии, формализма, легаси и открытые коммуникации."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Работу в профессиональной международной команде: возможность работать с коллегами, профессионалами своего дела мирового уровня, регулярное участие в международных конференциях и повышение квалификации."])}
        ]
      }
    },
    {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senior Programmatic Manager"])},
      "info": {
        "responsibilities": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выстраивание коммуникации с представителями источников трафика для улучшений технических показателей проектов."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контроль бюджетов, мониторинг конверсий и качества трафика, постоянная работа над улучшением показателей."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контроль работы CMS, разработка рекомендаций для оптимизаций рекламных кампаний в разрезе CPA, ROI."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Координирование работы отделов медиабаинга с новыми источниками трафика."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Постоянное взаимодействие со смежными отделами: аккаунты, сейлзы, аналитики, финансы."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оперативная техническая отладка настроек приложений, составление ТЗ и контроль своевременности решения технических проблем."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Генерация идей и подготовка презентаций для площадок-партнеров."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Составление регулярной отчетности."])}
        ],
        "requirements": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Высшее техническое образование."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опыт работы в Digital, Mobile, CPA на стороне агентства или CPA-сети."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опыт работы с мобильными трекерами: AppsFlyer, Appmetrica, Adjust, и т.п."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Понимание основных метрик для анализа эффективности CPI, CPA, ROI, LTV."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Знание процесса закупки мобильного трафика и его метрик, понимание работы рекламных кабинетов соцсетей и In-App сеток."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опыт работы с источниками трафика: Google Ads, TikTok, Huawei Ads, Xiaomi, Facebook, Unity, IronSource, In-App и др."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уверенное знание Exсel, Power BI."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аналитический склад ума."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Английский язык–Upper-Intermediate."])}
        ],
        "offer": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гибкий график и гибридный формат работы: рабочая неделя 5/2 с возможностью гибкого начала рабочего дня, дополнительные выходные."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Конкурентоспособную и стабильную зарплату и регулярные бонусы."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комфортные офисы в Москве с полноценным питанием, регулярные ивенты и другие бонусы, включая комнату отдыха с PS5, настольным теннисом, массажными креслами."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Драйвовую атмосферу: полное отсутствие бюрократии, формализма, легаси и открытые коммуникации."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Работу в профессиональной международной команде: возможность работать с коллегами, профессионалами своего дела мирового уровня, регулярное участие в международных конференциях и повышение квалификации."])}
        ]
      }
    },
    {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commercial Director (Digital)"])},
      "info": {
        "responsibilities": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активный поиск новых рекламодателей ивыстраивание долгосрочных партнерских отношений."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бизнес-развитие: представление интересов агентства при заключении новых сделок и эффективная защита предложений перед существующими клиентами."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Взаимодействие с клиентами, активное общение, инициирование переговоров, проактивный подход, регулярный сбор обратной связи."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Участие в конференциях, представление компании на профильных мероприятиях и расширение сети контактов."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление и улучшение отдела: координация и оптимизация работы отдела для достижения лучших результатов."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Укрепление клиентских отношений: поддержание продуктивных и долгосрочных отношений с как новыми, так и с текущими клиентами."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аналитика и поиск зон роста в B2B-продажах агентства."])}
        ],
        "requirements": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опыт в B2B продажах: значительный опыт работы с крупными клиентами и большими бюджетами."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Знание рынка мобильной рекламы, понимание текущего состояния рынка и ключевых игроков."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Умение строить процессы продаж: опыт в разработке и внедрении эффективных процессов."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опыт руководителем отдела продаж в агентской и digital сфере."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клиентоориентированный подход, умение мыслить с учетом потребностей клиентов и предпринимательский дух."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отличные коммуникативные навыки."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лидерские качества, практичность, способность работать в команде."])}
        ],
        "offer": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гибкий график и гибридный формат работы: рабочая неделя 5/2 с возможностью гибкого начала рабочего дня, дополнительные выходные."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Конкурентоспособную и стабильную зарплату и регулярные бонусы."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комфортные офисы в Москве с полноценным питанием, регулярные ивенты и другие бонусы, включая комнату отдыха с PS5, настольным теннисом, массажными креслами."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Драйвовую атмосферу: полное отсутствие бюрократии, формализма, легаси и открытые коммуникации."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Работу в профессиональной международной команде: возможность работать с коллегами, профессионалами своего дела мирового уровня, регулярное участие в международных конференциях и повышение квалификации."])}
        ]
      }
    },
    {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Analyst"])},
      "info": {
        "responsibilities": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regular data collection, reporting, analysis, and modification of dashboards"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maintain team statistics by reporting periods, preparing reports, and visualizing data"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Execute ad hoc requests"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write queries to internal databases"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work with push notifications in applications"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Control required team resources"])}
        ],
        "requirements": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excellent knowledge of MS Excel and Google Sheets"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proficient in SQL with experience working with large volumes of data"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analytical mind, attentiveness, and perseverance"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsibility, full dedication, and attention to detail"])}
        ],
        "offer": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гибкий график и гибридный формат работы: рабочая неделя 5/2 с возможностью гибкого начала рабочего дня, дополнительные выходные."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Конкурентоспособную и стабильную зарплату и регулярные бонусы."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комфортные офисы в Москве с полноценным питанием, регулярные ивенты и другие бонусы, включая комнату отдыха с PS5, настольным теннисом, массажными креслами."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Драйвовую атмосферу: полное отсутствие бюрократии, формализма, легаси и открытые коммуникации."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Работу в профессиональной международной команде: возможность работать с коллегами, профессионалами своего дела мирового уровня, регулярное участие в международных конференциях и повышение квалификации."])}
        ]
      }
    },
    {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senior Executive Recruiter"])},
      "info": {
        "responsibilities": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подбор персонала топ уровня;"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хантинг редких талантов;"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оценивать soft skills и формировать описание hard-компетенций для нанимающего менеджера;"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проводить аналитику рынка, конкурентов и подробный маппинг;"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Участвовать в брейнштормах, практиковать нестандартные подходы по установлению холодного контакта с кандидатами;"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Работать над общей базой кандидатов и развивать свой личный нетворк;"])}
        ],
        "requirements": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опыт работы в рекрутменте от 3-х лет;"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опыт поиска C-level специалистов;"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опыт в международном найме;"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опыт хантинга редких специалистов;"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Высокоразвитые коммуникативные, организаторские навыки, умение структурировать свое время и расставлять приоритеты;"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проактивность, умение применять различные методы поиска кандидатов на рынке в зависимости от позиции."])}
        ],
        "offer": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Интересная работа, возможность профессионального и карьерного роста;"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплачиваем все эффективные инструменты для подбора персонала;"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Достойный уровень заработной платы (фикс + KPI за каждого нанятого сотрудника);"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["График работы 5/2 (гибкое начало рабочего дня), отпуск 28 календарных дней;"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дружный и молодой коллектив единомышленников по всему миру;"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обеды в офисе, чай, кофе, сладости, свежие фрукты, корпоративы и тимбилдинги;"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Корпоративные скидки на изучение иностранного языка."])}
        ]
      }
    }
  ]
}