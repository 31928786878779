<script setup>
import { ref, reactive, onMounted } from 'vue'
import { useI18n } from 'vue-i18n';
import FeedbackFormSent from '@/components/FeedbackFormSent';

const { t } = useI18n();
const submitBtn = ref(null);
let isMessageSended = ref(false);
let isMessageFail = ref(false);

// eslint-disable-next-line no-undef
const props = defineProps({
  formInputs: {
    type: Object,
    default: () => {},
  },
  formCheckboxes: {
    type: Object,
    default: () => {},
  },
  buttonText: {
    type: String,
    default: '',
  },
  additionalField: {
    type: Object,
    default: () => {},
  },
})

const form = reactive({})

const checkbox = reactive({})

const resetForm = () => {
  for (let key in form) {
    if (Object.prototype.hasOwnProperty.call(form, key)) {
      form[key].value = ''
    }
  }

  for (let key in checkbox) {
    if (Object.prototype.hasOwnProperty.call(form, key)) {
      checkbox[key].checked = false
    }
  }
}

onMounted(() => {
  props.formInputs.forEach(input => {
    form[input.name] = {
      name: input.name,
      label: input.label,
      value: '',
      isValid: input.isValid,
      error: null,
      type: input.type,
    }
  })

  props.formCheckboxes.forEach(item => {
    checkbox[item.name] = {
      name: item.name,
      label: item.label,
      checked: false,
      isValid: item.isValid,
    }
  })
})

const formSubmit = () => {
  const api = `/mailer/index.php`;
  const data = {}
  let isValid = true
  const policyCheckbox = checkbox.policyCheckbox

  for (let key in form) {
    if (Object.prototype.hasOwnProperty.call(form, key)) {
      if (form[key].value === '') {
        form[key].error = t('feedbackForm.errorField')
        form[key].isValid = false
        isValid = false
      } else if (form[key].type === 'email' && !/.+@.+\..+/.test(form[key].value))  {
        form[key].error = t('feedbackForm.errorMail')
        form[key].isValid = false
        isValid = false
      } else {
        form[key].error = null
        form[key].isValid = true
        data[key] = form[key].value
      }
    }
  }

  policyCheckbox.isValid = policyCheckbox.checked

  if (isValid && policyCheckbox.checked) {
    submitBtn.value.disabled = true;

    if (props.additionalField) {
      data[props.additionalField.name] = props.additionalField.text;
    }

    policyCheckbox.isValid = policyCheckbox.checked

    if (isValid && policyCheckbox.checked) {
      submitBtn.value.disabled = true

      let str = []

      for (let key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
          str.push(`${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
        }
      }

      str = str.join('&')

      fetch(api, {
        method: 'POST',
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        body: str
      })
          .then(response => response.json())
          .then(data => {
            if (data.success) {
              isMessageSended.value = true

              setTimeout(() => {
                isMessageSended.value = false
                submitBtn.value.disabled = false
                resetForm()
              }, 5000)
            }
          })
          .catch(
              error => {
                if (error) {
                  isMessageSended.value = true
                  isMessageFail.value = true

                  setTimeout(() => {
                    isMessageSended.value = false
                    isMessageFail.value = false
                    submitBtn.value.disabled = false
                    resetForm()
                  }, 5000)
                }
              }
          )
    }
  }
}
</script>

<template>
  <form
      novalidate
      @submit.prevent="formSubmit"
      class="form"
  >
    <FeedbackFormSent
        v-if="form.isMessageSended"
        :is-message-fail="form.isMessageFail"
    />
    <slot name="title" />
    <div
      class="form__row"
      v-for="(field, i) in form"
      :key="i"
    >
      <input
          class="form__field"
          :type="field.type"
          :name="field.name"
          :placeholder="field.label"
          v-model="field.value"
      >
      <div
          class="form__field-error"
          v-if="!field.isValid"
      >
        {{ field.error }}
      </div>
    </div>

    <div class="form__checkbox"  v-for="(field, i) in checkbox" :key="i">
        <input
        :name="field.name"
        :id="field.name"
        type="checkbox"
        v-model="field.checked"
        :class="{'form__checkbox-input--error': !field.isValid}"
        />
        <label
        :for="field.name"
        v-html="field.label"
        ></label
        >
    </div>

    <div class="form__submit">
      <button
          class="btn btn_lg btn_block"
          type="submit"
          ref="submitBtn"
      >
        {{ buttonText }}
      </button>
    </div>
  </form>
</template>

<style lang="scss">
.form {
  position: relative;
  height: 100%;
  border-radius: 16px;
  padding: 45px 16px;
  background-color: $color-bgr-gray;

  @include s-sm {
    border-radius: 20px;
  }

  @include s-md {
    padding: 45px 24px;
  }

  @include s-lg {
    padding: 55px 32px;
  }

  @include s-xl {
    padding: 55px 46px;
  }

  &__row {
    position: relative;
    margin-bottom: 44px;

    @include s-lg {
      margin-bottom: 56px;
    }
  }

  &__field {
    width: 100%;
    padding: 0;
    border-width: 0 0 1px;
    border-style: solid;
    border-color: $color-text-light-gray;
    background-color: transparent;
    font-size: 16px;
    -webkit-appearance: none !important;
    border-radius: 0;

    @include s-lg {
      font-size: 22px;
    }

    &:focus {
      outline: none;
      border-color: $color-black;
    }

    &::placeholder {
      color: $color-text-light-gray;
    }
  }

  &__field-error {
    position: absolute;
    top: 100%;
    left: 0;
    padding-top: 6px;
    color: $color-red;
    font-size: 15px;
    font-weight: 700;
  }

  &__checkbox {
    margin: 10px;

    &-input {
        &--error {
            animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
        }
    }
  }

  @keyframes shake {
    10%, 90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%, 80% {
        transform: translate3d(2px, 0, 0);
    }

    30%, 50%, 70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%, 60% {
        transform: translate3d(4px, 0, 0);
    }
  }
}
</style>
