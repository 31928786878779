<script>
export default {
  computed: {
    computedSections() {
      return [
        {
          title: this.$t('sectionList.section1.title'),
          text: this.$t('sectionList.section1.text'),
          img: 'main-pic-1.png',
          imgEn: 'main_pic_1-en.png',
          url: 'cases'
        },
        {
          title: this.$t('sectionList.section2.title'),
          text: this.$t('sectionList.section2.text'),
          img: 'main-pic-2.png',
          imgEn: 'main_pic_2-en.png',
          url: 'solutions'
        }
      ]
    }
  }
}
</script>

<template>
  <div class="sections">
    <div
        class="sections__item"
        v-for="(section, i) in computedSections"
        :key="i"
    >
      <div class="sections__img">
        <img
            :src="require(`@/assets/img/${$i18n.locale === 'en' ? section.imgEn : section.img}`)"
            alt="logo"
        >
      </div>
      <div class="sections__body">
        <h3 class="sections__title title">{{ section.title }}</h3>
        <p class="sections__text text">{{ section.text }}</p>
        <p class="sections__more">
          <RouterLink class="sections__more-btn btn" :to="{ name: section.url, params: { locale: $i18n.locale }}">
            {{ $t('sectionList.button') }}
          </RouterLink>
        </p>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.sections {
  margin-bottom: 48px;

  @include s-md {
    margin-bottom: 62px;
  }

  @include s-lg {
    margin-bottom: 80px;
  }

  @include s-xl {
    margin-bottom: 180px;
  }

  &__item {
    margin-top: 48px;

    @include s-sm {
      display: flex;
      margin-right: -16px;
      margin-left: -16px;
      margin-top: 32px;
    }

    @include s-md {
      margin-top: 50px;
    }

    @include s-lg {
      margin-top: 80px;
    }

    @include s-xl {
      margin-top: 108px;
    }

    &:first-child {
      margin-top: 0;
    }
  }

  &__body {
    @include s-sm {
      width: 50%;
      flex: 0 0 auto;
      padding-right: 16px;
      padding-left: 16px;

      :nth-child(2n) > & {
        order: -1
      }
    }
  }

  &__title {
  }

  &__text {
    margin-top: 14px;
  }

  &__more {
    margin-top: 24px;

    @include s-sm {
      margin-top: 32px;
    }
  }

  &__more-btn {
    @include s-xs {
      display: flex;
      width: 100%;
      height: 40px;
    }
  }

  &__img {
    max-width: 320px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 16px;

    img {
      max-width: 528px;
    }

    @include s-sm {
      max-width: 50%;
      width: 50%;
      flex: 0 0 auto;
      margin-bottom: 0;
    }
  }
}
</style>
